import React from 'react';
import { IChurchGlobalStoreLocatorInformation } from '../../../actions/DataServiceEntities.g';
import { IHoursDisplayInfo } from './timeHelper';

interface StoreLocatorTableProps {
    regionStores: IChurchGlobalStoreLocatorInformation[];
    storeHours: IHoursDisplayInfo[][] | undefined;
    resources: any;
}

export const StoreLocatorTable: React.FC<StoreLocatorTableProps> = ({ regionStores, storeHours, resources }) => {
    if (regionStores && regionStores[0] !== undefined && storeHours !== undefined) {
        return (
            <div className='TableWrapper'>
                <table className='StyledTable'>
                    <tr className='StyledHeaderRow'>
                        <th className='StyledTableHeaderColOne'>{resources.storeLocatorTableHeader}</th>
                        <th className='StyledTableHeaderColTwo'>{resources.storeLocatorTableHeaderHours}</th>
                    </tr>

                    {regionStores && regionStores[0] !== undefined && storeHours !== undefined
                        ? regionStores.map((store, index) => {
                              const storeIndex = index;
                              return (
                                  <tr className='StyledRow' key={store.Id}>
                                      <th className='InnerTableHeader'>{resources.storeLocatorTableHeader}</th>
                                      <td className='StyledTableDataColOne'>
                                          <div>
                                              <div className='StyledStoreName'>{store.NAME}</div>
                                              <div>
                                                  {store.STREET}
                                                  {store.STREETNUMBER !== '' ? `, ${store.STREETNUMBER}` : ''}
                                              </div>
                                              <div>{store.DISTRICTNAME}</div>
                                              <div>{`${store.CITY}, ${store.STATE} ${store.ZIPCODE}`}</div>
                                              <div>{store.PHONE}</div>
                                          </div>
                                      </td>
                                      <th className='InnerTableHeader'>{resources.storeLocatorTableHeaderHours}</th>
                                      {storeHours[0] ? (
                                          <td className='StyledTableDataColTwo' style={{ padding: '20px' }}>
                                              <div>{resources.storeLocatorStoreHoursMessage}</div>
                                              <div
                                                  className='StyledTableHoursWrapper'
                                                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}
                                              >
                                                  <div className='StyledTableHours'>
                                                      {/* eslint-disable-next-line security/detect-object-injection */}
                                                      {storeHours[storeIndex]
                                                          ? /* eslint-disable-next-line security/detect-object-injection */
                                                            storeHours[storeIndex].map((hours, index) => {
                                                                return (
                                                                    <>
                                                                        <div>
                                                                            {hours.endDayFull
                                                                                ? `${hours.startDayFull} - ${hours.endDayFull}`
                                                                                : `${hours.startDayFull}`}{' '}
                                                                            {hours.timeRange
                                                                                ? `${hours.timeRange}`
                                                                                : resources.storeHoursClosedText}
                                                                        </div>
                                                                        {/* <div>{hours.timeRange ? `${hours.timeRange}` : 'Closed'}</div> */}
                                                                    </>
                                                                );
                                                            })
                                                          : null}
                                                  </div>
                                              </div>{' '}
                                          </td>
                                      ) : null}
                                  </tr>
                              );
                          })
                        : null}
                </table>
            </div>
        );
    } else {
        return <></>;
    }
};
