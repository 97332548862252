import React from 'react';
import { ICountryLanguageEntity, IChurchGlobalStoreLocatorInformation } from '../../../actions/DataServiceEntities.g';
import { Waiting } from '@msdyn365-commerce-modules/utilities';

interface StoreLocationProps {
    storeRegion: string;
    handleChange: any;
    state: string;
    handleClick: any;
    regionList: ICountryLanguageEntity[] | undefined;
    displayState: boolean;
    stateList: Object[];
    resources: any;
    regionStores: IChurchGlobalStoreLocatorInformation[];
}

export const StoreLocationSelector: React.FC<StoreLocationProps> = ({
    storeRegion,
    handleChange,
    handleClick,
    state,
    regionList,
    displayState,
    stateList,
    resources,
    regionStores
}) => {
    if (regionStores && regionStores[0] !== undefined) {
        return (
            <div className='LocationSelector'>
                <div className='SelectWrapper'>
                    <label className='SelectLabel' htmlFor=''>
                        {resources.storeLocatorCountryLabel}
                    </label>
                    <select
                        className='StoreLocatorSelect'
                        name='country'
                        id=''
                        value={storeRegion}
                        onChange={e => handleChange(e.target.value)}
                    >
                        <option className='StyledOption' value='' disabled selected>
                            {resources.defaultDropdown}
                        </option>
                        {regionList ? (
                            regionList.map(region => {
                                return (
                                    <option className='StyledOption' value={region.countryRegionId} key={region.Id}>
                                        {region.CountryName}
                                    </option>
                                );
                            })
                        ) : (
                            <option className='StyledOption' value='USA'>
                                United States
                            </option>
                        )}
                    </select>
                </div>
                {displayState ? (
                    <div className='SelectWrapper'>
                        <label className='SelectLabel' htmlFor=''>
                            {resources.storeLocatorStateLabel}
                        </label>
                        <select className='StoreLocatorSelect' name='state' id='' value={state} onChange={e => handleClick(e.target.value)}>
                            <option className='StyledOption' value='' disabled selected>
                                {resources.defalutDropdown}
                            </option>
                            {stateList.map((state: any) => {
                                return (
                                    <option className='StyledOption' value={state.abbreviation} key={state.name}>
                                        {state.name}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                ) : null}
            </div>
        );
    } else {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                <Waiting />
            </div>
        );
    }
};
